import React from 'react'
import { createRoot } from 'react-dom/client'
import DotSpin from '../loadAnimation/dotSpin'
import styles from './index.module.scss'
export default class ApiLoading {
  static rootLoading: any
  static type: string | undefined = undefined
  // ApiLoading?.type === 'alwatys' 时需手动调用关闭
  static show(loadingType?: 'always') {
    let oDiv = document.getElementById('api-loading')
    if (oDiv) return
    oDiv = document.createElement('div')
    document.body.appendChild(oDiv)
    oDiv.id = 'api-loading'
    oDiv.className = styles['loading-content']
    if (loadingType) this.type = loadingType
    const root = createRoot(oDiv)
    this.rootLoading = root
    root.render(
      <div>
        <DotSpin />
        <p>请求中...</p>
      </div>
    )
  }

  static hide = () => {
    const oDiv = document.getElementById('api-loading')
    if (this.rootLoading && oDiv) {
      this.rootLoading.unmount()
      document.body.removeChild(oDiv)
    }
    this.type = undefined
  }
}
