import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import $axios, { httpSuccess } from '@/http'
interface AsyncState {
  userList?: API.UserItem[]
  /* loading 用于标记当前所处状态，请求中、请求完成，请求错误 */
  loading?: 'loading' | 'finish' | 'error'
}
const initState: AsyncState = {}
// 创建异步action
export const getAsyncAction = createAsyncThunk(
  'async/require',
  async (query, option) => {
    // query传递过来参数
    if (query as any) {
      // 执行
    }
    // 获取状态值
    const state = option.getState() as AsyncState
    if (state?.userList) return state.userList
    // 异步请求数据
    const res = await $axios.get('user')
    if (httpSuccess(res?.status)) {
      return res.data
    }
    // 返回数据
  }
)
const asyncSlice = createSlice({
  name: 'async',
  initialState: initState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAsyncAction.fulfilled, (state, { payload }) => {
        // 赋值
        state.userList = payload as API.UserInfo[]
        state.loading = 'finish'
      })
      .addCase(getAsyncAction.rejected, (state) => {
        state.loading = 'error'
      })
      .addCase(getAsyncAction.pending, (state) => {
        state.loading = 'loading'
      })
  },
})

export default asyncSlice.reducer
