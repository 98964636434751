import { createReducer, createAction } from '@reduxjs/toolkit'
export const publicInit = createAction('public/refresh')

// 可以在其他reducer中引入该action，实现重置state(退出登录时可能会用到)
export const reset = createAction('public/reset')
interface PublicState {
  init?: boolean
}

const publicReducer = createReducer<PublicState>({}, (builder) => {
  builder
    .addCase(publicInit, (state) => {
      state.init = !state.init
    })
    .addCase(reset, (state) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state = {}
    })
})

export default publicReducer
