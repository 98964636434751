import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './reducers/rootReducer'

// 创建reducer store
const store = configureStore({
  reducer: rootReducer,
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export default store
