import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
export const accoutThunk = createAsyncThunk(
  'account/info',
  async (query: API.AccountInfo) => {
    if (query) {
      return query
    }
    // 否则去请求接口获取用户信息
    // ...
  }
)
interface AccountData extends API.AccountInfo {
  loading?: 'loading' | 'finish' | 'error'
}
const intiState: AccountData = {}
const accountSlice = createSlice({
  name: 'account',
  initialState: intiState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(accoutThunk.fulfilled, (state, { payload }) => {
        // 赋值
        state.loading = 'finish'
        state.limits = payload?.limits
        state.userinfo = payload?.userinfo
      })
      .addCase(accoutThunk.rejected, (state) => {
        state.loading = 'error'
      })
      .addCase(accoutThunk.pending, (state) => {
        state.loading = 'loading'
      })
  },
})

export default accountSlice.reducer
