/**
 * 权限key类型
 */
export type AuthKey = undefined | string | string[]
import { Route } from './router'

/**
 * @see https://umijs.org/zh-CN/plugins/plugin-access
 * */
export default function access(permissions: string[]) {
  /**
   * 通用权限判断
   *
   * 字符串: 判断是否存在在权限数组中
   * 字符串数组: 判断是否存在任意一个数据在权限数组中
   */
  const commonAccess = (authKey?: AuthKey): boolean => {
    if (!authKey) {
      return true
    }

    if (authKey?.includes('****')) return true

    if (typeof authKey === 'string') {
      return permissions?.includes?.(authKey) || false
    }

    if (Array.isArray(authKey)) {
      return authKey.some((key) => permissions?.includes(key))
    }

    return false
  }

  return {
    commonAccess,
    /** 是否已登录 */
    // isLogin: () => {
    //   return isLogin
    // },
    /** 路由权限校验 */
    routeAccess: (props: Route) => {
      // return true;
      return commonAccess(props.limit)
    },
  }
}
