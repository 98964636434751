// https://books.sangniao.com/manual/2512864574/4051908290 路由文档
import { lazy } from 'react'
import { createBrowserRouter, RouteObject } from 'react-router-dom'
// import App from '@/App'
// import ErrorRouter from '@/pages/error/404'
// import MainLayout from '@/pages/layout'
import RouterLoading from './loading'
const HomePage = lazy(() => import('@/pages/layout/home'))

export type Route = Omit<RouteObject, 'children'> & {
  limit?: string
  name?: string
  children?: Route[]
}

const routes: Route[] = [
  // {
  //   element: <App />,
  //   errorElement: <ErrorRouter />,
  //   children: [
  //     {
  //       name: '主页',
  //       path: '/main',
  //       element: <MainLayout />,
  //       errorElement: <ErrorRouter />,
  //       children: [
  //         {
  //           name: '首页',
  //           path: '/main/home',
  //           element: <RouterLoading element={<HomePage />} />,
  //           errorElement: <ErrorRouter />,
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    path: '/',
    element: <RouterLoading element={<HomePage />} />,
    // element: <Navigate to='/main/home' />,
  },
]

const router = createBrowserRouter(routes as RouteObject[])

export { router, routes }
