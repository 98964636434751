import styles from './index.module.scss'
export default function DotSpin() {
  return (
    <div className={styles['loading']} data-v-0cf49291=''>
      <div data-v-0cf49291='' />
      <div data-v-0cf49291='' />
      <div data-v-0cf49291='' />
      <div data-v-0cf49291='' />
      <div data-v-0cf49291='' />
      <div data-v-0cf49291='' />
      <div data-v-0cf49291='' />
      <div data-v-0cf49291='' />
    </div>
  )
}
