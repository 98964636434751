import { combineReducers, Action } from '@reduxjs/toolkit'
import publicReducer from '../publicReducer'
import asyncReducer from '../asyncReducer'
import accountReducer from '../accountReducer'
// 合并所有reducers
const allReducer = combineReducers({
  public: publicReducer,
  async: asyncReducer,
  account: accountReducer,
})

export type AppReducerType = ReturnType<typeof allReducer>

const rootReducer = (rootState: AppReducerType | undefined, action: Action) => {
  if (action.type === 'reset/store') {
    // 清空所有state，此方法也可重置所有state，可用于退出登录
    rootState = undefined
  }
  return allReducer(rootState, action)
}

export default rootReducer
