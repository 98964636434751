import { ReactNode, Suspense } from 'react'
import DotSpin from '@c/loadAnimation/dotSpin'
import { Navigate, useLocation } from 'react-router-dom'
import { useAppSelector } from '@/store/hooks'
import { routes, Route } from '.'
import access from '@/access'

export default function RouterLoading({ element }: { element: ReactNode }) {
  const limits = useAppSelector((state) => state.account.limits)
  const currentRoute = useLocation().pathname
  // 扁平化处理函数
  function flatten(arr: Route[]): Route[] {
    return arr.reduce(function (pre, item) {
      return pre.concat(
        Array.isArray(item.children) ? flatten(item.children) : item
      )
    }, [] as Route[])
  }
  const flatRoute = flatten(routes)

  // 获取当前路由配置项
  const currentRouteData = flatRoute.find((item) => item.path === currentRoute)

  // 是否有权限标识
  let check = true
  if (currentRouteData?.limit) {
    check = access(limits || []).routeAccess(currentRouteData)
  }
  return (
    <Suspense fallback={<DotSpin />}>
      {check ? element : <Navigate to='/405' replace />}
    </Suspense>
  )
}
