import axios, { AxiosResponse } from 'axios'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'
import qs from 'qs'
// import { useNavigate } from 'react-router-dom'
import ApiLoading from '@c/loading'
import URLLIST from './urlList'
// const navigate = useNavigate()
// console.log(navigate)
interface RequestData extends Record<string, any> {
  /* *传参方式：
   *form：问号后面跟参数，用&拼接参数，get，delete请求默认方式
   *path: 通过/参数拼接在url后,改方式指定参数名为id
   *json：json传参方式，post、put默认方式
   * */
  paramsType?: 'form' | 'path' | 'json'
}

export interface ListType<T extends object> {
  size?: number
  total?: number
  pages?: number
  current?: number
  records: T[]
}

export interface ResponsType<T extends object> {
  code: number
  data: T | ListType<T>
  msg?: string
}

export const httpSuccess = (key: number | boolean) => {
  if (key === 200) return true
  return false
}

class Axios {
  instance: AxiosInstance
  constructor() {
    this.instance = axios.create({
      baseURL: '/api',
      timeout: 5000,
    })

    // 请求拦截
    this.instance.interceptors.request.use(
      (config) => {
        if (
          config?.params &&
          (config.method === 'get' || config.method === 'delete')
        ) {
          const { h1, ...data } = config.params
          config.params = data
          if (h1) ApiLoading.show()
        } else {
          if (config.data && (config.data?.h1 || config.data.includes('h1'))) {
            // 表单传参还是json传参
            if (
              config.headers?.['Content-Type'] ===
              'application/x-www-form-urlencoded'
            ) {
              // 表单
              const postData = qs.parse(config.data)
              const { h1, ...data } = postData
              config.data = qs.stringify(data)
              if (h1) ApiLoading.show()
            } else {
              const { h1, ...data } = config.data
              config.data = data
              if (h1) ApiLoading.show()
            }
          }
        }

        //token拦截
        // const token = localStorage.getItem('token')
        // if (token) {
        //   config.headers = {
        //     ...(config.headers || {}),
        //     Authorization: token,
        //   }
        // }
        return config
      },
      (error) => {
        return error
      }
    )

    // 响应拦截
    this.instance.interceptors.response.use(
      (res) => {
        if (httpSuccess(res?.data?.code)) {
          // 请求成功并且数据正确
          setTimeout(() => {
            // 依次请求的接口，不在此关闭
            if (ApiLoading?.type !== 'always') ApiLoading.hide()
          })
          return res
        } else {
          ApiLoading.hide()
        }
        return res
      },
      (error) => {
        ApiLoading.hide()
        return error
      }
    )
  }

  // 获取真实请求地址
  urlMap<T extends object, K extends keyof T>(urlList: T, key: K): T[K] | K {
    return urlList[key] ?? key
  }

  // get请求
  get<T extends RequestData, D extends object>(
    url: HttpUrlKey,
    params?: T,
    config?: Omit<AxiosRequestConfig, 'parmas'>
  ): Promise<AxiosResponse<ResponsType<D>, T>> {
    let path = this.urlMap(URLLIST, url)
    if (params && params?.paramsType === 'path') {
      // 参数{paramsType: 'path',id: string}
      path += '/' + params?.id
    }
    return this.instance.get(path, {
      params:
        params?.paramsType === 'path'
          ? params?.h1
            ? { h1: params.h1 }
            : undefined
          : params,
      ...config,
    })
  }

  // post请求
  post<T extends RequestData>(
    url: HttpUrlKey,
    data?: T,
    config?: AxiosRequestConfig
  ) {
    const path = this.urlMap(URLLIST, url)
    let { paramsType, ...postData } = data || {}
    if (paramsType === 'form') {
      // 此方式在参数中多加一个{paramsType: 'form'}
      postData = qs.stringify(postData, { arrayFormat: 'repeat' })
    }
    return this.instance.post(path, postData, {
      headers: {
        'Content-Type':
          paramsType === 'form'
            ? 'application/x-www-form-urlencoded'
            : 'application/json',
      },
      ...config,
    })
  }

  // put请求
  put<T extends RequestData>(
    url: HttpUrlKey,
    data?: T,
    config?: AxiosRequestConfig
  ) {
    const path = this.urlMap(URLLIST, url)
    let { paramsType, ...postData } = data || {}
    if (paramsType === 'form') {
      postData = qs.stringify(postData, { arrayFormat: 'repeat' })
    }
    return this.instance.put(path, postData, {
      headers: {
        'Content-Type':
          paramsType === 'form'
            ? 'application/x-www-form-urlencoded'
            : 'application/json',
      },
      ...config,
    })
  }

  // delete请求
  del<T extends RequestData>(
    url: HttpUrlKey,
    params?: T,
    config?: Omit<AxiosRequestConfig, 'parmas'>
  ) {
    let path = this.urlMap(URLLIST, url)
    if (params && params?.paramsType === 'path') {
      path += '/' + params?.id
    }
    return this.instance.delete(path, {
      params:
        params?.paramsType === 'path'
          ? params?.h1
            ? { h1: params?.h1 }
            : undefined
          : params,
      ...config,
    })
  }
}
const $axios = new Axios()

export default $axios

/*
// let params = [1, 2, 3];

// indices(默认)
qs.stringify({a: params}, {
  arrayFormat: 'indices'
})
// 结果是
'a[0]=1&a[1]=2&a[2]=3'

// brackets
qs.stringify({a: params}, {
  arrayFormat: 'brackets'
})
// 结果是
'a[]=1&a[]=2&a[]=3'

// repeat
qs.stringify({a: params}, {
  arrayFormat: 'repeat'
})
// 结果是
'a=1&a=2&a=3'
*/
